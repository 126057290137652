import { Document, Paragraph, TextRun, HeadingLevel } from "docx";
import FORM_LABELS from "../constants/formLabels2025";
import parseDate from "./parseDate";

const assessmentLabels = [
    {
        stage: "Етап 1. Оцінювання заступником директора Школи",
        assessment: {
            stageOnePoints: "Кількість балів",
            stageOneComment: "Коментар",
            stageOneAssessment: "Фінальна оцінка",
        },
    },
    {
        stage: "Етап 2. Оцінки тестування та психолога",
        assessment: {
            stageTwoMathPoints: "Кількість балів за Математику",
            stageTwoMathComment: "Коментар вчителя Математики",
            stageTwoMathAssessment: "Фінальна оцінка за Математику",

            stageTwoUkrainianPoints: "Кількість балів за Українську мову",
            stageTwoUkrainianComment: "Коментар вчителя Української мови",
            stageTwoUkrainianAssessment: "Фінальна оцінка за Українську мову",

            stageTwoEnglishPoints: "Кількість балів за Англійську мову",
            stageTwoEnglishComment: "Коментар вчителя Англійської мови",
            stageTwoEnglishAssessment: "Фінальна оцінка за Англійську мову",

            stageTwoNaturePoints: "Кількість балів за Природничі науки",
            stageTwoNatureComment: "Коментар вчителя Природничих наук",
            stageTwoNatureAssessment: "Фінальна оцінка за Природничі науки",

            stageTwoPsychologistComment: "Коментар психолога",
            stageTwoPsychologistAssessment: "Фінальна оцінка психолога",
        },
    },
    {
        stage: "Етап 3. Співбесіда",
        assessment: {
            stageThreePoints: "Кількість балів",
            stageThreeAssessment: "Фінальна оцінка",
        },
    },
];

const createApplicationWordFile = (application) => {
    const createLabelParagraph = (label) => {
        const labelParagraph = new Paragraph({
            spacing: {
                after: 240,
            },
            children: [
                new TextRun({
                    text: FORM_LABELS[label],
                    bold: true,
                    font: "Arial",
                }),
            ],
        });

        return labelParagraph;
    };

    const createDataParagraph = (label) => {
        let data = application[label];

        if (label === "birthdayDate") {
            data = parseDate(data).split(",")[0];
        }

        if (data === true || data === false) {
            data = data === true ? "Так" : "Ні";
        }

        const dataParagraph = new Paragraph({
            children: [
                new TextRun({
                    text: String(data),
                    font: "Arial",
                }),
            ],
        });

        return dataParagraph;
    };

    const createAssessments = () => {
        let results = [
            new Paragraph({
                heading: HeadingLevel.HEADING_2,
                spacing: {
                    after: 240,
                },
                children: [
                    new TextRun({
                        text: "Оцінювання",
                        bold: true,
                        allCaps: true,
                        color: "000000",
                        font: "Arial",
                    }),
                ],
            }),
        ];

        assessmentLabels.forEach((label) => {
            results.push(
                new Paragraph({
                    spacing: {
                        after: 240,
                    },
                    children: [
                        new TextRun({
                            text: label.stage,
                            bold: true,
                            font: "Arial",
                        }),
                    ],
                }),
                new Paragraph({
                    children: [],
                })
            );

            Object.keys(label.assessment).forEach((i) => {
                const parlabel = label.assessment[i];
                const assessment = application.assessment[i];
                const value = !assessment ? "не оцінено" : assessment;
                results.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `${parlabel}: ${value}`,
                                font: "Arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        children: [],
                    })
                );
            });
        });

        return results;
    };

    const parseLabels = () => {
        let results = [
            new Paragraph({
                heading: HeadingLevel.HEADING_1,
                spacing: {
                    after: 240,
                },
                children: [
                    new TextRun({
                        text: application.name,
                        bold: true,
                        allCaps: true,
                        color: "000000",
                        font: "Arial",
                    }),
                ],
            }),
        ];
        Object.keys(FORM_LABELS).forEach((label) => {
            results.push(createLabelParagraph(label));
            results.push(createDataParagraph(label));
            results.push(
                new Paragraph({
                    children: [],
                })
            );
        });

        results = [...results, ...createAssessments()];

        return results;
    };

    const doc = new Document({
        sections: [
            {
                children: parseLabels(),
            },
        ],
    });

    return doc;
};

export default createApplicationWordFile;
