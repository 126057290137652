import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { usePDF, Margin } from "react-to-pdf";
import { Packer } from "docx";

// components
import PageMetadata from "components/PageMetadata/PageMetadata";
import PageTitle from "components/PageTitle/PageTitle";
import Loader from "components/Loader/Loader";
import ApplicationInformation2025 from "components/2025/ApplicationInformation2025/ApplicationInformation2025";
import AssessmentForm2025 from "components/AssessmentForm2025/AssessmentForm2025";
import Button from "components/UI/Button/Button";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Modal from "components/UI/Modal/Modal";
import Input from "components/UI/Input/Input";
import PageSubtitle from "components/UI/PageSubtitle/PageSubtitle";

// states
import { selectAdminRole } from "features/authSlice";

// api
import API from "app/api";

// services
import authHeader from "services/auth-header";
import AuthService from "services/auth.service";
import createApplicationWordFile from "features/createApplication2025WordFile";

// constants
import { PROJECT_ROLE_NAME_BY_TITLES } from "constants/projectRoles";

// styles
import "./style.scss";

// icons
import LeftArrowIcon from "assets/icons/left-arrow.svg";
import handleError from "api/handleError";

// constants
const PAGE_TITLE = "Заявка";

const ApplicationPage2025 = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isAdmin = useSelector(selectAdminRole);

    const [isLoading, setIsLoading] = useState(true);
    const [isDeletingModalOpen, setIsDeletingModalOpen] = useState(false);

    const [application, setApplication] = useState(undefined);
    const [code, setCode] = useState("");

    const [userProjectRole, setUserProjectRole] = useState(AuthService.getProjectRoleName());
    const [printPageView, setPrintPageView] = useState(false);

    const { toPDF, targetRef } = usePDF({
        filename: `Заявка №${id}${application ? " " + application?.name : ""}.pdf`,
        page: { margin: Margin.MEDIUM },
    });

    useEffect(() => {
        id &&
            axios
                .post(API.application2025.readOneById, { id }, { headers: authHeader() })
                .then((response) => {
                    const { data } = response;
                    setApplication(data);
                    setCode(data.code);
                    setIsLoading(false);
                })
                .catch((error) => {
                    if (error.response.status && [401, 403].includes(error.response.status)) {
                        AuthService.logout();
                        navigate("/signin");
                        window.location.reload();
                    } else if (error.response.status && error.response.status === 404) {
                        navigate("/404");
                    } else {
                        handleError(error, dispatch, navigate);
                    }
                });
    }, [navigate]);

    const codeHandler = (value) => {
        setCode(value);
    };

    const userProjectRoleHandler = (value) => {
        setUserProjectRole(PROJECT_ROLE_NAME_BY_TITLES[value]);
    };

    const downloadPagePdf = () => {
        toPDF();
    };

    const downloadPageDocx = () => {
        const docx = createApplicationWordFile(application);
        Packer.toBlob(docx).then((blob) => {
            const date = new Date();
            const fileName = `Анкета ${application.name} (${date.toLocaleString("uk")}).docx`;
            const href = window.URL.createObjectURL(blob);

            const anchorElement = document.createElement("a");

            anchorElement.href = href;
            anchorElement.download = fileName;

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        });
    };

    const printPageViewHandler = () => {
        setPrintPageView((prev) => {
            if (prev) {
                setUserProjectRole(AuthService.getProjectRoleName());
            } else {
                setUserProjectRole("project manager");
            }
            return !prev;
        });
    };

    const deleteApplication = () => {
        id &&
            axios
                .post(API.application2025.deleteApplicationById, { id }, { headers: authHeader() })
                .then((response) => {
                    navigate("/2025/applications");
                    window.location.reload();
                })
                .catch((error) => {
                    if (error.response.status && [401, 403].includes(error.response.status)) {
                        AuthService.logout();
                        navigate("/signin");
                        window.location.reload();
                    } else {
                        navigate("/error", { state: { error: error.response.data.message } });
                    }
                });
    };

    const updateCode = () => {
        id &&
            axios
                .post(API.application2025.updateCodeById, { id, code }, { headers: authHeader() })
                .then((response) => {
                    window.location.reload();
                })
                .catch((error) => {
                    if (error.response.status && [401, 403].includes(error.response.status)) {
                        AuthService.logout();
                        navigate("/signin");
                        window.location.reload();
                    } else {
                        navigate("/error", { state: { error: error.response.data.message } });
                    }
                });
    };

    const deletingModalHandler = () => {
        setIsDeletingModalOpen((prev) => !prev);
    };

    return (
        <>
            <PageMetadata title={`${PAGE_TITLE} №${id}`} />
            <main className="container">
                <div className="page application-page">
                    <PageTitle pageTitle={`Заявка №${id}`} />

                    <div className="application-page-control">
                        <div>
                            <Link className="application-page-return" to="/2025/applications">
                                <img src={LeftArrowIcon} />
                                <span>До всіх анкет</span>
                            </Link>
                        </div>
                        {isAdmin && (
                            <div>
                                <Button content="Завантажити в DOCX" handler={downloadPageDocx} disabled={!application} />
                                {printPageView && <Button content="Завантажити в PDF" handler={downloadPagePdf} />}
                                <Button content={printPageView ? "Звичайна версія" : "Версія для друку"} handler={printPageViewHandler} />
                            </div>
                        )}
                    </div>

                    {isAdmin && (
                        <div className="application-page-code">
                            <Input label="Шифр анкети" value={code} handler={codeHandler} />
                            <ButtonGroup>
                                <Button content="Зберегти" handler={updateCode} />
                            </ButtonGroup>
                        </div>
                    )}

                    {application && (
                        <div className="application-page-divider" ref={targetRef} data-print={printPageView}>
                            <ApplicationInformation2025
                                application={application}
                                userProjectRole={userProjectRole}
                                printView={printPageView}
                            />
                            <AssessmentForm2025
                                id={id}
                                applicationAssessment={application.assessment}
                                userProjectRole={userProjectRole}
                                userProjectRoleHandler={userProjectRoleHandler}
                                printView={printPageView}
                            />
                            {isAdmin && !printPageView && (
                                <div className="application-page-deleting">
                                    <PageSubtitle pageSubtitle="Видалення анкети" />
                                    <p>
                                        <span>Важливо!</span> Даний функціонал видаляє анкету та її оцінювання повністю та остаточно.
                                        Використовуйте його лише за крайньої необхідності. Функціонал доступний тільки адміністраторам
                                        сайту.
                                    </p>

                                    <ButtonGroup position="end">
                                        <Button content="Видалити анкету" handler={deletingModalHandler} />
                                    </ButtonGroup>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </main>
            {isLoading && <Loader />}
            {isDeletingModalOpen && (
                <Modal
                    title={`Ви впевнені, що хочете видалити анкету ${application.name}?`}
                    message="Ця дія призведе до безповоротного видалення даних анкети та оцінювання із системи."
                    closeHandler={deletingModalHandler}
                    handler={deleteApplication}
                    buttonText="Видалити"
                />
            )}
        </>
    );
};

export default ApplicationPage2025;
