import React, { useState } from "react";

//components
import Button from "../UI/client/Button/Button";

//styles
import "./style.scss";

//images
import eyesIllustration from "../../assets/img/illustrations/eyes-illustration.svg";
import attentionIllustration from "../../assets/img/illustrations/attention-illustration.svg";
import asteriskIcon from "../../assets/icons/asterisk.svg";
import titleArrowIllustration from "../../assets/img/illustrations/title-arrow.svg";
import actionsIllustration from "../../assets/img/illustrations/actions-illustration.svg";
import actionsMobileIllustration from "../../assets/img/illustrations/actions-illustration-mobile.svg";
import personPhoto from "../../assets/img/photo/person-photo.jpg";

const LandingInformation = ({ nowDate, startDate, endDate }) => {
    const isClosed = nowDate < startDate || nowDate >= endDate;

    return (
        <div>
            <div className="hero">
                <div className="hero-content">
                    <div className="hero-content-text">
                        <h1 className="hero-content-text-title">Стипендіальна програма</h1>
                        {nowDate >= startDate && nowDate < endDate && (
                            <p className="hero-content-text-description">Відкритий прийом заявок на безоплатне навчання</p>
                        )}

                        {nowDate < startDate && (
                            <p className="hero-content-text-description">Прийом заявок у 2025 році скоро розпочнеться!</p>
                        )}
                        {nowDate >= endDate && <p className="hero-content-text-description">Прийом заявок у 2025 році завершено!</p>}
                    </div>
                    <div className="hero-content-action">
                        {isClosed ? (
                            <Button content="Подати заявку" type="button" disabled={true} />
                        ) : (
                            <Button content="Подати заявку" type="anchor" link="#application" />
                        )}
                    </div>
                </div>
                <div className="hero-illustration"></div>
            </div>

            <div className="about">
                <h2 className="about-title">
                    <span>Що таке</span>
                    <span className="about-title-highlighted">стипендіальна програма?</span>
                </h2>
                <div className="about-description">
                    <h3 className="about-description-title">
                        Стипендіальна програма — це грант від громадської спілки «Освіторія» та Новопечерської школи на безоплатне навчання.
                    </h3>
                    <p className="about-description-paragraph">
                        Його мета — надати можливість талановитим учням та ученицям 8-11 класів здобувати якісну повну загальну середню
                        освіту, розвивати лідерські навички в одній із найбільш інноваційних шкіл України та світу — Новопечерській школі.
                    </p>

                    <p className="about-description-paragraph">
                        Грант повністю покриває вартість навчання в Новопечерській школі в Києві для родин із низьким рівнем доходу або
                        нижче середнього. В умовах повномасштабної війни гранти також надаються учням та ученицям із родин, які опинилися в
                        складних життєвих обставинах через російське воєнне вторгнення.
                    </p>
                    <p className="about-description-paragraph">
                        Стипендіальна програма приймає дітей з усієї країни. На час навчання стипендіати з інших міст перебуватимуть під
                        наглядом наставника. Школярі забезпечуються житлом, харчуванням та усім необхідним для навчання.
                    </p>
                </div>
            </div>

            <div className="conditions">
                <div className="conditions-card">
                    <img className="conditions-card-illustration" src={attentionIllustration} alt="card-illustration" />
                    <div className="conditions-card-content">
                        <h3 className="conditions-card-content-title">
                            Які <span>обовʼязкові умови</span> для участі
                            <img src={titleArrowIllustration} />
                        </h3>

                        <ul>
                            <li>бути учнем/ученицею 8-11 класу в наступному 2025/2026 навчальному році;</li>
                            <li>мати високі академічні, творчі, спортивні здобутки;</li>
                            <li>​бути проактивним/-ою, ініціативним/-ою та відповідальним/-ою;</li>
                            <li>належати до родини з низьким рівнем доходу або нижче середнього.</li>
                        </ul>
                    </div>
                    <div className="conditions-card-note">
                        <img src={asteriskIcon} />
                        <p>
                            <span>
                                Соціальні стипендії також надаються учням із родин, які опинилися в складних життєвих обставинах через
                                повномасштабне воєнне вторгнення росії.
                            </span>
                        </p>
                    </div>
                </div>
                <div className="conditions-card">
                    <img className="conditions-card-illustration" src={eyesIllustration} alt="card-illustration" />
                    <div className="conditions-card-content">
                        <h3 className="conditions-card-content-title">
                            Як <span>отримати грант</span> на навчання
                            <img src={titleArrowIllustration} />
                        </h3>

                        <ul>
                            <li>​заповнити онлайн-форму до 15 квітня 2025 року;</li>
                            <li>протягом 21 – 26 квітня</li>
                        </ul>

                        <ol>
                            <li>
                                1) успішно скласти вступні академічні випробування з математики, української мови, англійської мови та
                                природничих наук;
                            </li>
                            <li>2) узяти участь у командній взаємодії з іншими учасниками / учасницями конкурсу;</li>
                        </ol>
                        <ul>
                            <li>
                                пройти фінальну співбесіду в школі за участі претендента / претендентки та його / її батьків або осіб, які
                                їх заміняють (червень 2025 року).
                            </li>
                        </ul>
                    </div>

                    <div className="conditions-card-note">
                        <img src={asteriskIcon} />
                        <p>
                            Претендент чи претендентка може також бути рекомендованим (-ою) на грант кимось із оточення. Для цього потрібно
                            заповнити відповідну онлайн-форму, а куратор програми зв’яжеться з рекомендованими учнями та розповість про
                            деталі.
                        </p>
                    </div>
                </div>
            </div>

            <div className="actions">
                <div className="actions-title">
                    Стипендіальна програма
                    <br />
                    2025
                </div>
                <div className="actions-illustration">
                    <img className="actions-illustration-desktop" src={actionsIllustration} />
                    <img className="actions-illustration-mobile" src={actionsMobileIllustration} />
                </div>
                <div className="actions-buttons">
                    {isClosed ? (
                        <>
                            <Button content="Подати заявку" type="button" disabled={true} />
                            <Button content="Рекомендувати" type="button" disabled={true} />
                        </>
                    ) : (
                        <>
                            <Button content="Подати заявку" type="anchor" link="#application" />
                            <Button
                                content="Рекомендувати"
                                type="link"
                                link="https://docs.google.com/forms/d/e/1FAIpQLScjn0ZQKmpnI1EZukuRX_3sxapCrk9xTaFTPF3-Yw5uOQ830Q/viewform"
                                target="_blank"
                            />
                        </>
                    )}
                </div>
            </div>

            <div className="quote">
                <div className="quote-person">
                    <img src={personPhoto} />
                    <div>
                        <h4>Зоя Литвин</h4>
                        <p>Голова ГС «Освіторія»</p>
                        <p>Засновниця Новопечерської школи</p>
                    </div>
                </div>

                <div className="quote-content">
                    <h3 className="quote-content-title">«Я переконана, що освіта створює "завтра" для нашої країни.</h3>
                    <p className="quote-content-text">
                        Тож всі наші проєкти спрямовані на те, щоб якомога більше дітей мали доступ до різних і рівних можливостей для
                        здобуття якісної освіти. Стипендіальна програма розрахована на талановитих і здібних дітей, майбутніх лідерів, які
                        мають неосяжні мрії та готові працювати для їхнього здійснення. В умовах повномасштабного воєнного вторгнення ми
                        також надаємо гранти на навчання дітям із родин, які постраждали та опинилися в скрутних життєвих обставинах. Це наш
                        спосіб підтримати українські родини в такі непрості часи. Ми пишаємося кожним із наших стипендіатів і з нетерпінням
                        чекаємо зустрічі з амбітними учасниками програми нового навчального року».
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LandingInformation;
